.pagination-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .pagination {
    display: flex;
    align-items: center;
  }
  
  .pagination-btn {
    background-color: #f1f1f1;
    border: none;
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    padding: 6px 0; /* Set the left and right padding to 0 */
    width: 30px; /* Set the width of the buttons */
    border-radius: 4px;
  }
  
  .pagination-btn:hover {
    background-color: #ddd;
  }
  
  .pagination-btn.active {
    background-color: #4CAF50;
    color: white;
  }
  
  .pagination-btn.disabled {
    background-color: #f1f1f1;
    color: #ccc;
    cursor: not-allowed;
  }
  
  .date-picker-container .date-picker {
    width: 100px; /* Adjust the width as needed */
    margin-right: 20px;
  }
  